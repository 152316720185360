import React, { Component } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import moment from 'moment'

class Service extends Component {
  render() {
    const {
      failureType,
      functionalityStatus,
      handleInputChange,
      procedureCode,
      serviceElevation,
      serviceNotice,
      serviceOrderNumber,
      serviceType,
      warrantyType,
      failureTypeList,
      serviceLocationList,
      serviceElevationList,
      serviceNoticeList,
      serviceTypeByRole,
      warrantyTypeList,
      functionalityStatusList,
      serviceOrderList,
      selectedRepeatOrder,
      customerRequestedDate,
      customerRequestedWindow,
      customerRequestedWindowArray,
      handleCustomerRequestedDate,
      symptomCode,
      symptomCodeList,
      failureDt,
      isSymptomCodeValidated,
      isSymptomCodeValid,
      handleFailureDateSelect,
      isFailureNoteValidated,
      isFailureNoteValid,
      failureNote,
      servicerNotes,
    } = this.props
    return (
      <Row md={2}>
        <Col md={6}>
          <h4>Service Information</h4>
          <Form.Group as={Row} controlId='serviceOrderNumber'>
            <Form.Label column xs={6}>
              Service Order Number :
            </Form.Label>
            <Col xs={6}>
              <Form.Control plaintext readOnly value={serviceOrderNumber} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='serviceElevation'>
            <Form.Label column xs={6}>
              Priority Level:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                as='select'
                value={serviceElevation}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
              >
                {serviceElevationList?.map((elevation, i) => (
                  <option key={i} value={elevation.elevationName}>
                    {elevation.elevationName}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='serviceNotice'>
            <Form.Label column xs={6}>
              Notice:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                as='select'
                value={serviceNotice}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
              >
                {serviceNoticeList?.map((notice, i) => (
                  <option key={i} value={notice.noticeName}>
                    {notice.noticeName}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          {serviceNotice === 'Repeat Request' ? (
            <Form.Group as={Row} controlId='relatedTo'>
              <Form.Label column xs={6}>
                Related To:
              </Form.Label>
              <Col xs={6}>
                <Form.Control
                  as='select'
                  value={selectedRepeatOrder ? selectedRepeatOrder : null}
                  size='sm'
                  onChange={(event) => {
                    handleInputChange(event)
                  }}
                >
                  <option value=''>Select an order</option>
                  {serviceOrderList?.map((order, i) => (
                    <option key={i} value={order.serviceOrderNumber}>
                      {`${order.serviceOrderNumber}  Filed Date-${moment(order.createdOn).format(
                        'YYYY-MM-DD',
                      )}`}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          ) : null}
          <Form.Group as={Row} controlId='warrantyType'>
            <Form.Label column xs={6}>
              Warranty Type:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                as='select'
                value={warrantyType}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
              >
                <option value='' disabled>
                  Select warranty type...
                </option>
                {warrantyTypeList?.map((warranty, i) => (
                  <option key={i} value={warranty.warrantyTypeName}>
                    {warranty.warrantyTypeName}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='serviceType' className='mb-0'>
            <Form.Label column xs={6}>
              Service Type:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                as='select'
                value={serviceType}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
              >
                <option value='' disabled>
                  Select service type...
                </option>
                {serviceTypeByRole?.map((type, i) => (
                  <option key={i} value={type.serviceTypeName}>
                    {type.serviceTypeName}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='procedureCode' className='mb-0'>
            <Form.Label column xs={6}>
              Procedure Code:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                as='select'
                value={procedureCode}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
              >
                <option value='' disabled>
                  Select procedure code...
                </option>
                {serviceLocationList?.map((code, i) => (
                  <option key={i} value={code.serviceLocationName}>
                    {code.serviceLocationName}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='functionalityStatus' className='mb-0'>
            <Form.Label column xs={6}>
              Functionality Status:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                as='select'
                value={functionalityStatus}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
              >
                {functionalityStatusList?.map((functionality, i) => (
                  <option key={i} value={functionality.functionalityStatusName}>
                    {functionality.functionalityStatusName}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='failureType' className='mb-0'>
            <Form.Label column xs={6}>
              Failure Type:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                as='select'
                value={failureType}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
              >
                {failureTypeList?.map((failureType, i) => (
                  <option key={i} value={failureType.failureTypeName}>
                    {failureType.failureTypeName}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group
            as={Row}
            controlId='symptomCode'
            className='mb-1'
            style={{ marginTop: '4rem' }}
          >
            <Form.Label column xs={6}>
              Symptom Code:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                className={
                  !isSymptomCodeValidated ? null : isSymptomCodeValid ? 'is-valid' : 'is-invalid'
                }
                as='select'
                value={symptomCode}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
              >
                <option value='' disabled>
                  Select symptom code...
                </option>
                {symptomCodeList?.map((symptomCode) => (
                  <option key={symptomCode.description} value={symptomCode.description}>
                    {symptomCode.description}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type='invalid'>
                Please provide a symptom code.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='failureDt'>
            <Form.Label column xs={6}>
              Failure Date:
            </Form.Label>
            <Col xs={6}>
              <DatePicker
                as={Form.Control}
                selected={new Date()}
                onSelect={(date) => handleFailureDateSelect(date)}
                value={failureDt}
                maxDate={new Date()}
                disabledKeyboardNavigation
                aria-describedby='failureDtHelpBlock'
                className='custom-datepicker'
              />
              <Form.Text id='failureDtHelpBlock' muted>
                <br></br>If unknown, use today's date.
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='failureNote' className='mb-1'>
            <Form.Label column xs={6}>
              Complaint:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                as='textarea'
                placeholder='failure note'
                value={failureNote}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
                className={
                  !isFailureNoteValidated ? null : isFailureNoteValid ? 'is-valid' : 'is-invalid'
                }
              />
              <Form.Control.Feedback type='invalid'>
                Please provide the complaint.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='servicerNotes' className='mb-1'>
            <Form.Label column xs={6}>
              Servicer Notes:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                as='textarea'
                placeholder='notes'
                value={servicerNotes}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='customerRequestedDate'>
            <Form.Label column xs={6}>
              Customer Requested Date:
            </Form.Label>
            <Col xs={6}>
              <DatePicker
                as={Form.Control}
                selected={new Date()}
                onSelect={(date) => handleCustomerRequestedDate(date)}
                value={customerRequestedDate}
                minDate={new Date()}
                disabledKeyboardNavigation
                aria-describedby='failureDtHelpBlock'
                className='custom-datepicker'
              />
              <Form.Text id='failureDtHelpBlock' muted>
                <br></br>If unknown, use today's date.
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='customerRequestedSlot'>
            <Form.Label column xs={6}>
              Customer Requested Window:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                as='select'
                value={customerRequestedWindow || 'Morning'}
                size='sm'
                onChange={(event) => {
                  handleInputChange(event)
                }}
              >
                {customerRequestedWindowArray?.map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
      </Row>
    )
  }
}

export default Service
