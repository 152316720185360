import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { StreetInput } from '../../StreetInput'
import {
  getCountryDetailByCode,
  getCountryList,
  getStateDetail,
  validateAddress,
} from '../../../api/serviceavenger'
import ConfirmationModal from './ConfirmationModal'
import PaymentSuccessModal from './PaymentSuccessModal'

class CustomerInfo extends Component {
  state = {
    firstName: '',
    lastName: '',
    phone: '',
    confirmPhone: '',
    email: '',
    confirmEmail: '',
    amount: '',
    confirmAmount: '',
    address: '',
    address1: '',
    country: '',
    state: '',
    city: '',
    postalCode: '',
    stateCode: '',
    countryCode: '',
    countryId: '',
    regionCode: '',
    countryList: [],
    stateList: [],
    errorMessageContractModal: '',
    isAddressValid: null,
    isAddressValidated: false,
    isCityValid: null,
    isCityValidated: false,
    isEmailValid: null,
    isEmailValidated: false,
    isConfirmedEmailValid: null,
    isConfirmedEmailValidated: false,
    isPostalCodeValid: null,
    isPostalCodeValidated: false,
    isStateValid: null,
    isCountryValid: null,
    isStateValidated: false,
    isCountryValidated: false,
    isAmountValid: null,
    isAmountValidated: false,
    isConfirmAmountValid: null,
    isConfirmAmountValidated: false,
    isPrimaryPhoneValidated: false,
    isPrimaryPhoneValid: null,
    isConfirmedPrimaryPhoneValidated: false,
    isConfirmedPrimaryPhoneValid: null,
    countyCode: '',
    isCountryDisabled: true,
    openConfirmationModal: false,
  }

  resetValidation = () => {
    this.setState({
      isAddressValid: null,
      isAddressValidated: false,
      isCityValid: null,
      isCityValidated: false,
      isEmailValid: null,
      isEmailValidated: false,
      isFirstNameValid: null,
      isPostalCodeValid: null,
      isPostalCodeValidated: false,
      isStateValid: null,
      isCountryValid: null,
      isStateValidated: false,
      isCountryValidated: false,
      isPrimaryPhoneValid: null,
      isAmountValid: null,
      isAmountValidated: false,
      isPrimaryPhoneValidated: false,
      isConfirmAmountValid: null,
      isConfirmAmountValidated: false,
      isConfirmedEmailValid: null,
      isConfirmedEmailValidated: false,
      isConfirmedPrimaryPhoneValidated: false,
      isConfirmedPrimaryPhoneValid: null,
    })
  }
  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  validatePhone(phone) {
    const cleanString = phone.replace(/\D/g, '')
    return cleanString?.length === 10
  }
  validateModal = async () => {
    const {
      address,
      city,
      email,
      postalCode,
      phone,
      state,
      country,
      confirmEmail,
      confirmPhone,
      amount,
      confirmAmount,
    } = this.state

    let errorMessageContractModal = ''
    const body = {
      address: {
        firstAddressLine: this.state.address,
        city: this.state.city,
        stateCode: this.state.stateCode,
        zipCode: this.state.postalCode,
        country: this.state.regionCode,
      },
    }

    if (!(await this.isValidAddress(body))) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while validating address</b><br/>`,
        isAddressValid: false,
        isAddressValidated: true,
        isCityValid: false,
        isCityValidated: true,
        isPostalCodeValid: false,
        isPostalCodeValidated: true,
        isStateValid: false,
        isStateValidated: true,
      })
    }

    this.setState({
      errorMessageContractModal,
      isAddressValid: address?.length > 0 ? true : false,
      isAddressValidated: true,
      isCityValid: city?.length > 0 ? true : false,
      isCityValidated: true,
      isEmailValid:
        email?.trim().length === 0 && email?.toUpperCase() === confirmEmail?.toUpperCase()
          ? false
          : this.validateEmail(email?.trim()),
      isEmailValidated: true,
      isConfirmedEmailValid:
        email?.trim().length === 0 && email?.toUpperCase() === confirmEmail?.toUpperCase()
          ? false
          : this.validateEmail(confirmEmail?.trim()) &&
            email?.toUpperCase() === confirmEmail?.toUpperCase(),
      isConfirmedEmailValidated: true,
      isPostalCodeValid: postalCode?.length ? true : false,
      isPostalCodeValidated: true,
      isPrimaryPhoneValid:
        phone?.trim().length === 0 && confirmPhone?.toUpperCase() === phone?.toUpperCase()
          ? false
          : this.validatePhone(phone?.trim()),
      isAmountValid:
        amount?.length > 0 && confirmAmount?.length > 0 && +amount === +confirmAmount
          ? true
          : false,
      isConfirmedPrimaryPhoneValid:
        phone?.trim().length === 0 && confirmPhone?.toUpperCase() === phone?.toUpperCase()
          ? false
          : this.validatePhone(confirmPhone?.trim()) &&
            confirmPhone?.toUpperCase() === phone?.toUpperCase(),
      isPrimaryPhoneValidated: true,
      isConfirmedPrimaryPhoneValidated: true,
      isAmountValidated: true,
      isConfirmAmountValid: confirmAmount?.length > 0 && +amount === +confirmAmount ? true : false,
      isConfirmAmountValidated: true,
      isStateValid: state?.length > 0 ? true : false,
      isCountryValid: country?.length > 0 ? true : false,
      isStateValidated: true,
      isCountryValidated: true,
    })
    if (this.props.reimbursementMethod === 'Check') {
      return (
        address?.length > 0 &&
        city?.length > 0 &&
        this.validateEmail(email) &&
        postalCode?.length > 0 &&
        phone?.replace(/\D/g, '').length === 10 &&
        state?.length > 0 &&
        amount?.length > 0 &&
        confirmPhone?.toUpperCase() === phone?.toUpperCase() &&
        Number(amount) === Number(confirmAmount) &&
        email?.toUpperCase() === confirmEmail?.toUpperCase()
      )
    } else {
      return (
        this.validateEmail(email) &&
        amount?.length > 0 &&
        phone?.replace(/\D/g, '').length === 10 &&
        confirmPhone?.toUpperCase() === phone?.toUpperCase() &&
        Number(amount) === Number(confirmAmount) &&
        email?.toUpperCase() === confirmEmail?.toUpperCase()
      )
    }
  }

  isValidAddress = async (address) => {
    if (this.props.reimbursementMethod !== 'Check') {
      return
    }
    try {
      const response = await validateAddress(address)
      if (response?.data?.isSuccess) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }
  removeExtraStringFromPhoneNumber(phoneNumber) {
    if (phoneNumber.length > 0) {
      return phoneNumber.replace(/[()\s-]/g, '')
    }
    return phoneNumber
  }
  getCountryList = async () => {
    try {
      const response = await getCountryList()
      const { status, data } = response
      if (status === 200 && data?.items?.length > 0) {
        this.setState({ countryList: data?.items || [] })
      }
    } catch (error) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while getting Country list:</b><br/>${error.message}`,
      })
    }
  }
  getStateList = async (countryId) => {
    try {
      const response = await getStateDetail(countryId)
      const { status, data } = response
      if (status === 200 && data?.items?.length > 0) {
        this.setState({ stateList: data?.items || [] })
        const selectedState = data?.items.find((item) => item?.stateCode === this.props.state)
        this.setState({
          state: selectedState.stateName,
          stateCode: selectedState?.stateCode,
        })
      }
    } catch (error) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while getting state list:</b><br/>${error.message}`,
      })
    }
  }

  countryDetailByCode = async (code) => {
    try {
      const response = await getCountryDetailByCode(code)
      const { status, data } = response

      if (status === 200 && data?.items?.length > 0) {
        const selectedCountry = data.items[0]

        if (selectedCountry) {
          this.getStateList(selectedCountry.countryId)
        }
        this.setState({
          country: selectedCountry?.countryName,
          countryId: selectedCountry?.countryId,
          countyCode: selectedCountry?.countryCode,
          regionCode: selectedCountry?.regionCode,
        })
      }
    } catch (error) {
      this.setState({
        errorMessageContractModal: `<br/><b>Error while getting country detail:</b><br/>${error.message}`,
      })
    }
  }

  onRender = () => {
    this.getCountryList()
    this.countryDetailByCode(this.props.country)
  }
  handleInputChange = async (event) => {
    this.resetValidation()
    if (event != null) {
      const { target } = event
      const { id, value } = target

      switch (id) {
        case 'phone':
          this.setState({
            [id]: value,
          })
          break

        case 'full-address':
          const filterSelectedCountry = this.state.countryList?.find(
            (item) => item?.regionCode === value?.country,
          )
          this.setState(
            {
              city: value.city,
              postalCode: value.postalCode,
              stateCode: value?.state,
              country: filterSelectedCountry?.countryName,
              countryId: filterSelectedCountry?.countryId,
              countyCode: filterSelectedCountry?.countryCode,
              regionCode: filterSelectedCountry?.regionCode,
            },
            async () => {
              if (filterSelectedCountry?.countryId) {
                const stateList = await getStateDetail(filterSelectedCountry?.countryId)
                if (stateList?.data?.items?.length > 0) {
                  const filteredState = stateList?.data?.items?.find(
                    (item) => item?.stateCode === value?.state,
                  )
                  if (!!filteredState) {
                    this.setState({
                      state: filteredState?.stateName,
                    })
                  }
                }
              }
            },
          )
          break

        case 'state/Province':
          const selectedState = this.state.stateList?.find((item) => item?.stateName === value)
          this.setState({
            state: value,
            stateCode: selectedState?.stateCode,
          })
          break
        default:
          this.setState({ [id]: value })
      }
    }
  }
  onSubmit = async () => {
    if (await this.validateModal()) {
      this.setState({ openConfirmationModal: true })
    }
  }
  closeConfirmationModal = () => {
    this.setState({ openConfirmationModal: false })
  }
  closePaymentSuccessModal = () => {
    const { closeCustomerInfoDialog, closeSubClaimDialog, handleClosePaymentSuccessModal } =
      this.props
    handleClosePaymentSuccessModal()
    closeCustomerInfoDialog()
    closeSubClaimDialog()
  }
  handleYesConfirmationModal = () => {
    const {
      firstName,
      lastName,
      phone,
      email,
      amount,
      address,
      address1,
      country,
      state,
      city,
      postalCode,
      stateCode,
      countryCode,
      countryId,
      regionCode,
    } = this.state
    const {
      causeComments,
      causeType,
      correctiveActionDescription,
      correctiveAction,
      reimbursementMethod,
    } = this.props
    const data = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      amount: amount,
      address: address,
      address1: address1,
      country: country,
      state: state,
      city: city,
      postalCode: postalCode,
      stateCode: stateCode,
      countryCode: countryCode,
      countryId: countryId,
      regionCode: regionCode,
      causeComments: causeComments,
      causeType: causeType,
      correctiveActionDescription: correctiveActionDescription,
      correctiveAction: correctiveAction,
      reimbursementMethod: reimbursementMethod,
    }
    const { handleCreateClaim } = this.props
    handleCreateClaim(data)
  }
  render() {
    const {
      openCustomerInfoDialog,
      closeCustomerInfoDialog,
      reimbursementMethod,
      openPaymentSuccessModal,
    } = this.props
    const {
      stateList,
      countryList,
      country,
      city,
      state,
      postalCode,
      address,
      isAddressValid,
      isAddressValidated,
      isCityValid,
      isCityValidated,
      isEmailValid,
      isEmailValidated,
      isPostalCodeValid,
      isPostalCodeValidated,
      isPrimaryPhoneValid,
      isAmountValid,
      isPrimaryPhoneValidated,
      isAmountValidated,
      isStateValid,
      isCountryValid,
      isStateValidated,
      isCountryValidated,
      openConfirmationModal,
      firstName,
      lastName,
      email,
      amount,
      phone,
      isConfirmAmountValid,
      isConfirmAmountValidated,
      isConfirmedEmailValid,
      isConfirmedEmailValidated,
      isConfirmedPrimaryPhoneValidated,
      isConfirmedPrimaryPhoneValid,
      countryCode,
      stateCode,
      confirmAmount,
      confirmEmail,
      confirmPhone,
    } = this.state
    const handleConfirmationTextMessage = () => {
      if (reimbursementMethod === 'Check') {
        return 'The customer shall be present at the above address to receive the check'
      } else {
        return 'Are you sure you want to process these details for payment ?'
      }
    }
    const isCheckMethod = reimbursementMethod === 'Check' ? true : false
    return (
      <React.Fragment>
        {!openConfirmationModal && !openPaymentSuccessModal && (
          <Modal
            onShow={this.onRender}
            show={openCustomerInfoDialog}
            onHide={closeCustomerInfoDialog}
          >
            <Modal.Header closeButton>
              <Modal.Title className='text-center'>Customer Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label column='sm' className=' mb-3'>
                {isCheckMethod ? (
                  '(Please make sure to verify  the customer details like Name, Address, Phone, and Email with the customer.)'
                ) : (
                  <React.Fragment>
                    {`Please make sure the phone and email provided below match the one on ${
                      reimbursementMethod || ''
                    } account`}
                  </React.Fragment>
                )}
              </Form.Label>
              <Form.Group as={Row} controlId='firstName'>
                <Form.Label column xs={6}>
                  First Name :
                </Form.Label>
                <Col xs={6}>
                  <Form.Control
                    value={firstName}
                    autoComplete='off'
                    size='sm'
                    type='text'
                    placeholder='First Name'
                    className='mb-2'
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='lastName'>
                <Form.Label column xs={6}>
                  Last Name :
                </Form.Label>
                <Col xs={6}>
                  <Form.Control
                    value={lastName}
                    autoComplete='off'
                    size='sm'
                    type='text'
                    placeholder='Last Name'
                    className='mb-2'
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='phone'>
                <Form.Label column xs={6}>
                  Phone :
                </Form.Label>
                <Col xs={6}>
                  <Form.Control
                    value={phone}
                    autoComplete='off'
                    size='sm'
                    type='text'
                    placeholder='Phone'
                    className={
                      !isPrimaryPhoneValidated
                        ? null
                        : isPrimaryPhoneValid
                        ? 'is-valid'
                        : 'is-invalid'
                    }
                    maxLength={14}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='confirmPhone'>
                <Form.Label column xs={6}>
                  Confirm Phone :
                </Form.Label>
                <Col xs={6}>
                  <Form.Control
                    value={confirmPhone}
                    autoComplete='off'
                    size='sm'
                    type='text'
                    placeholder='Confirm Phone'
                    className={
                      !isConfirmedPrimaryPhoneValidated
                        ? null
                        : isConfirmedPrimaryPhoneValid
                        ? 'is-valid'
                        : 'is-invalid'
                    }
                    maxLength={14}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Col>
              </Form.Group>
              <Form.Control.Feedback type='invalid'>Phone must match</Form.Control.Feedback>
              <Form.Group as={Row} controlId='email'>
                <Form.Label column xs={6}>
                  Email :
                </Form.Label>
                <Col xs={6}>
                  <Form.Control
                    value={email}
                    autoComplete='off'
                    size='sm'
                    type='Email'
                    placeholder='Email'
                    className={!isEmailValidated ? null : isEmailValid ? 'is-valid' : 'is-invalid'}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='confirmEmail'>
                <Form.Label column xs={6}>
                  Confirm Email :
                </Form.Label>
                <Col>
                  <Form.Control
                    value={confirmEmail}
                    autoComplete='off'
                    size='sm'
                    type='email'
                    placeholder='Confirm Email'
                    className={
                      !isConfirmedEmailValidated
                        ? null
                        : isConfirmedEmailValid
                        ? 'is-valid'
                        : 'is-invalid'
                    }
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Col>
              </Form.Group>
              <Form.Control.Feedback type='invalid'>Email must match.</Form.Control.Feedback>
              {reimbursementMethod === 'Check' && (
                <React.Fragment>
                  <Form.Group as={Row} controlId='country'>
                    <Form.Label column xs={6}>
                      Country:
                    </Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        as='select'
                        autoComplete='off'
                        className={
                          !isCountryValidated ? null : isCountryValid ? 'is-valid' : 'is-invalid'
                        }
                        onChange={(event) => {
                          this.handleInputChange(event)
                        }}
                        size='sm'
                        value={country}
                      >
                        <option key='' value=''>
                          Select Country...
                        </option>
                        {countryList && countryList.length > 0 ? (
                          countryList.map((country, i) => (
                            <option key={i} value={country?.countryName}>
                              {country?.countryName}
                            </option>
                          ))
                        ) : (
                          <option key='no-data' value='' disabled>
                            No data found
                          </option>
                        )}
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>
                        Please select a Country.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='address'>
                    <Form.Label column xs={6}>
                      Address:
                    </Form.Label>
                    <Col xs={6}>
                      <StreetInput
                        address={address}
                        handleInputChange={this.handleInputChange}
                        isAddressValid={isAddressValid}
                        isAddressValidated={isAddressValidated}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='address2'>
                    <Form.Label column xs={6}>
                      Address 2:
                    </Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        autoComplete='off'
                        onChange={(event) => {
                          this.handleInputChange(event)
                        }}
                        placeholder='Additional Address'
                        size='sm'
                        type='text'
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='city'>
                    <Form.Label column xs={6}>
                      City:
                    </Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        autoComplete='off'
                        className={
                          !isCityValidated ? null : isCityValid ? 'is-valid' : 'is-invalid'
                        }
                        onChange={(event) => {
                          this.handleInputChange(event)
                        }}
                        placeholder='City'
                        size='sm'
                        type='text'
                        value={city}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Please provide a city.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='state/Province'>
                    <Form.Label column xs={6}>
                      State/Province:
                    </Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        as='select'
                        autoComplete='off'
                        className={
                          !isStateValidated ? null : isStateValid ? 'is-valid' : 'is-invalid'
                        }
                        onChange={(event) => {
                          this.handleInputChange(event)
                        }}
                        size='sm'
                        value={state}
                      >
                        <option key='' value=''>
                          Select State/Province: ...
                        </option>
                        {stateList && stateList.length > 0 ? (
                          stateList.map((state, i) => (
                            <option key={i} value={state?.stateName}>
                              {state?.stateName}
                            </option>
                          ))
                        ) : (
                          <option key='no-data' value='' disabled>
                            No data found
                          </option>
                        )}
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>
                        Please select a State/Province: .
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='postalCode'>
                    <Form.Label column xs={6}>
                      Postal Code:
                    </Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        autoComplete='off'
                        className={
                          !isPostalCodeValidated
                            ? null
                            : isPostalCodeValid
                            ? 'is-valid'
                            : 'is-invalid'
                        }
                        maxLength={10}
                        onChange={(event) => {
                          this.handleInputChange(event)
                        }}
                        placeholder='Postal Code'
                        size='sm'
                        type='text'
                        value={postalCode}
                      />
                    </Col>
                    <Form.Control.Feedback type='invalid'>
                      Please provide a valid postal code.
                    </Form.Control.Feedback>
                  </Form.Group>
                </React.Fragment>
              )}
              <Form.Group as={Row} controlId='amount'>
                <Form.Label column xs={6}>
                  Amount :
                </Form.Label>
                <Col xs={6}>
                  <Form.Control
                    value={amount}
                    autoComplete='off'
                    size='sm'
                    type='number'
                    className={
                      !isAmountValidated ? null : isAmountValid ? 'is-valid' : 'is-invalid'
                    }
                    placeholder='Enter Amount'
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='confirmAmount'>
                <Form.Label column xs={6}>
                  Confirm Amount :
                </Form.Label>
                <Col>
                  <Form.Control
                    value={confirmAmount}
                    autoComplete='off'
                    size='sm'
                    type='number'
                    placeholder='Confirm Amount'
                    className={
                      !isConfirmAmountValidated
                        ? null
                        : isConfirmAmountValid
                        ? 'is-valid'
                        : 'is-invalid'
                    }
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Col>
              </Form.Group>
              <Form.Control.Feedback type='invalid'>Amount must match.</Form.Control.Feedback>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => closeCustomerInfoDialog()}>
                Close
              </Button>
              <Button variant='primary' onClick={this.onSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {openConfirmationModal && !openPaymentSuccessModal && (
          <ConfirmationModal
            openConfirmationModal={openConfirmationModal}
            closeConfirmationModal={this.closeConfirmationModal}
            handleYesConfirmationModal={this.handleYesConfirmationModal}
            confirmText={'Proceed'}
            cancelText={'Close'}
            textMessage={handleConfirmationTextMessage()}
            userDetail={{
              firstName: firstName,
              lastName: lastName,
              phone: phone,
              email: email,
              amount: amount,
              address: address,
              city: city,
              postalCode: postalCode,
              stateCode: stateCode,
              countryCode: countryCode,
            }}
          />
        )}
        {openPaymentSuccessModal && (
          <PaymentSuccessModal
            openPaymentSuccessModal={openPaymentSuccessModal}
            closePaymentSuccessModal={this.closePaymentSuccessModal}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            amount={amount}
            reimbursementMethod={reimbursementMethod}
            addressObj={{
              address: this.state.address,
              address1: this.state.address1,
              countryCode: this.state.countryCode,
              city: this.state.city,
              stateCode: this.state.stateCode,
              postalCode: this.state.postalCode,
            }}
          />
        )}
      </React.Fragment>
    )
  }
}
export default CustomerInfo
