import React from 'react'
import { Component } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'

class ConfirmationModal extends Component {
  render() {
    const {
      closeConfirmationModal,
      handleYesConfirmationModal,
      openConfirmationModal,
      confirmText,
      cancelText,
      textMessage,
      userDetail = {},
    } = this.props
    const {
      firstName = '',
      lastName = '',
      phone = '',
      email = '',
      amount = '',
      address = '',
      city = '',
      postalCode = '',
      stateCode = '',
      countryCode = '',
    } = userDetail || {}
    const handleConcatAddress = () => {
      const fullAddress =
        address + ' ' + city + ' ' + stateCode + ' ' + countryCode + ' ' + postalCode
      return address ? fullAddress : ''
    }
    return (
      <React.Fragment>
        <Modal
          show={openConfirmationModal}
          onHide={closeConfirmationModal}
          size='ms'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label column='md' className='text-center'>
              {textMessage}
            </Form.Label>
            <Card border='Light' className='mb-2'>
              <Card.Body>
                <Card.Text>
                  <Row xs={12} as={Row}>
                    <Col xs={4}>First Name : </Col>
                    <Col xs={8}>{firstName}</Col>
                  </Row>
                  <Row xs={12} as={Row}>
                    <Col xs={4}>Last Name : </Col>
                    <Col xs={8}>{lastName}</Col>
                  </Row>
                  <Row xs={12} as={Row}>
                    <Col xs={4}>Phone : </Col>
                    <Col xs={8}>{phone}</Col>
                  </Row>
                  <Row xs={12} as={Row}>
                    <Col xs={4}>Email : </Col>
                    <Col xs={8}>{email}</Col>
                  </Row>
                  <Row xs={12} as={Row}>
                    <Col xs={4}>Address : </Col>
                    <Col xs={8}>{handleConcatAddress()}</Col>
                  </Row>
                  <Row xs={12} as={Row}>
                    <Col xs={4}>Amount : </Col>
                    <Col xs={8}>{amount}</Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={closeConfirmationModal}>
              {cancelText}
            </Button>
            <Button variant='primary' onClick={handleYesConfirmationModal}>
              {confirmText}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}
export default ConfirmationModal
