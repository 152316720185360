import axios from 'axios'
import { validatePayloadDates } from '../Utils/DateValidator'

let cancelTokenSource = axios.CancelToken.source()

const Interceptors = (onUnauthorized) => {
  const requestInterceptor = axios.interceptors.request.use(
    (config) => {
      if (config?.data) {
        config.data = validatePayloadDates(config.data)
      }
      const token = localStorage.getItem('loginToken')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json'
      }
      config.cancelToken = cancelTokenSource.token
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  const responseInterceptor = axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.clear()
        if (onUnauthorized) {
          onUnauthorized()
        }
        cancelTokenSource.cancel('Unauthorized')
      }
      return Promise.reject(error)
    },
  )

  return { requestInterceptor, responseInterceptor }
}

const clearCancelToken = () => {
  cancelTokenSource.cancel('Operation canceled by the user.')
  cancelTokenSource = axios.CancelToken.source()
}

export { Interceptors, clearCancelToken }
