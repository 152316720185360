import React, { Component } from 'react'
import { Button, Card, Col, Form, FormControl, InputGroup, Modal, Row } from 'react-bootstrap'
import { getDropdownData } from '../../api/serviceavenger'
import './Claim.css'
import CustomerInfo from './Components/CustomerInfo'
class Claim extends Component {
  state = {
    correctiveMethodList: [],
    causeMethodList: [],
    correctiveAction: '',
    correctiveActionDescription: '',
    causeType: '',
    causeComments: '',
    reimbursementMethod: '',
    expectedLiability: '',
    getCreateDispatchSubClaimData: {},
    isValidReimbursementMethod: null,
    isValidatedReimbursementMethod: false,
    isValidCorrectiveAction: null,
    isValidatedCorrectiveActionDescription: false,
    isValidCorrectiveActionDescription: null,
    isValidatedCauseComments: false,
    isValidCauseComments: null,
    isValidatedCauseType: false,
    isValidCauseType: null,
    isValidatedCorrectiveAction: false,
    isValidForCreateSubClaim: false,
    isValidateForSubClaim: null,
    isValidatedForSubClaim: false,
    isError: false,
    serviceTypeId: null,
    openCustomerInfoWindow: false,
  }
  getDropDownDataList = async () => {
    const arr = ['correctiveMethodList', 'causeMethodList']
    for (const item of arr) {
      const template = item?.charAt(0)?.toUpperCase() + item?.slice(1, item?.length)
      const response = await getDropdownData(template)
      if (response && response?.data?.isSuccess) {
        this.setState({ [item]: response?.data?.items })
      }

      try {
      } catch (error) {}
    }
  }
  componentDidMount = async () => {
    this.getDropDownDataList()
  }
  resetValidation = () => {
    this.setState({
      isValidReimbursementMethod: null,
      isValidatedReimbursementMethod: false,
      isValidatedForSubClaim: false,
      isValidateForSubClaim: null,
      isValidCorrectiveAction: null,
      isValidatedCorrectiveAction: false,
      isValidatedCorrectiveActionDescription: false,
      isValidCorrectiveActionDescription: null,
      isValidatedCauseComments: false,
      isValidCauseComments: null,
      isValidCauseType: null,
      isValidatedCauseType: false,
    })
  }
  validateModal = () => {
    const {
      reimbursementMethod,
      expectedLiability,
      isValidForCreateSubClaim,
      correctiveAction,
      correctiveActionDescription,
      causeComments,
      causeType,
    } = this.state
    isValidForCreateSubClaim ? (
      <React.Fragment>
        {this.setState({
          isValidReimbursementMethod: reimbursementMethod?.length > 0 ? true : false,
          isValidatedReimbursementMethod: true,
          isValidCorrectiveAction: correctiveAction?.length > 0 ? true : false,
          isValidatedCorrectiveAction: true,
          isValidCauseComments: causeComments?.length > 0 ? true : false,
          isValidatedCauseComments: true,
          isValidCorrectiveActionDescription:
            correctiveActionDescription?.length > 0 ? true : false,
          isValidatedCorrectiveActionDescription: true,
          isValidatedCauseType: true,
          isValidCauseType: causeType?.length > 0 ? true : false,
        })}
      </React.Fragment>
    ) : (
      this.setState({
        isValidateForSubClaim: expectedLiability?.length > 0 ? true : false,
        isValidatedForSubClaim: true,
      })
    )
    if (isValidForCreateSubClaim) {
      return (
        reimbursementMethod?.length > 0 &&
        correctiveActionDescription?.length > 0 &&
        causeComments?.length > 0 &&
        correctiveAction?.length > 0 &&
        causeType?.length > 0
      )
    } else {
      return expectedLiability?.length > 0
    }
  }
  handleSubmit = async () => {
    const { serviceType, serviceTypeByRole } = this.props

    const data = serviceTypeByRole?.filter((item) => item?.serviceTypeName === serviceType)
    this.setState({ serviceTypeId: data[0]?.serviceTypeId })
    // this.resetValidation()
    if (this.validateModal()) {
      if (!this.state.isValidForCreateSubClaim) {
        this.handleIsValidForSubClaim()
      } else {
        this.openCustomerInfoDialog()
      }
    }
  }
  openCustomerInfoDialog = () => {
    this.setState({
      openCustomerInfoWindow: true,
    })
  }
  closeCustomerInfoDialog = () => {
    this.setState({
      openCustomerInfoWindow: false,
    })
  }
  handleIsValidForSubClaim = () => {
    const expectedLiability = this.state.expectedLiability
    const remainingLol = this.props.lolAvailableMoneyFormat
    const filteredRemainingLol = remainingLol?.slice(1, remainingLol?.length)
    if (Number(expectedLiability) <= Number(filteredRemainingLol)) {
      this.setState({ isValidForCreateSubClaim: true })
    } else {
      this.setState({ isValidateForSubClaim: false })
      this.setState({ isError: true })
    }
  }
  handleInputChange = (event) => {
    this.resetValidation()
    const { target } = event
    const { id, value } = target
    this.setState({
      [id]: value,
    })
    if (id === 'expectedLiability') {
      this.setState({ isError: false })
    }
  }
  render() {
    const {
      correctiveMethodList,
      causeMethodList,
      isValidReimbursementMethod,
      correctiveAction,
      correctiveActionDescription,
      causeType,
      causeComments,
      reimbursementMethod,
      isValidatedReimbursementMethod,
      isValidForCreateSubClaim,
      isValidatedForSubClaim,
      isValidateForSubClaim,
      isError,
      openCustomerInfoWindow,
      isValidCorrectiveAction,
      isValidatedCorrectiveAction,
      isValidatedCorrectiveActionDescription,
      isValidCorrectiveActionDescription,
      isValidatedCauseComments,
      isValidCauseComments,
      isValidCauseType,
      isValidatedCauseType,
    } = this.state
    const {
      closeSubClaimDialog,
      openSubClaimWindow,
      lolAvailableMoneyFormat = '',
      firstName = '',
      lastName = '',
      phone = '',
      email = '',
      country = '',
      openPaymentSuccessModal,
      handleOpenPaymentSuccessModal,
      handleClosePaymentSuccessModal,
    } = this.props

    return (
      <React.Fragment>
        {!openCustomerInfoWindow && (
          <Modal show={openSubClaimWindow} onHide={closeSubClaimDialog}>
            <Modal.Header closeButton>
              <Modal.Title>Create Sub Claim</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isValidForCreateSubClaim ? (
                <React.Fragment>
                  <Form.Group as={Row} controlId='correctiveAction'>
                    <Form.Label column xs={6}>
                      Corrective Action :
                    </Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        as='select'
                        autoComplete='off'
                        className={
                          !isValidatedCorrectiveAction
                            ? null
                            : isValidCorrectiveAction
                            ? 'is-valid'
                            : 'is-invalid'
                        }
                        onChange={(event) => {
                          this.handleInputChange(event)
                        }}
                        size='sm'
                        value={correctiveAction}
                      >
                        <option key='' value=''>
                          Select Corrective Action...
                        </option>
                        {Array.isArray(correctiveMethodList) && correctiveMethodList.length > 0 ? (
                          correctiveMethodList.map((item, i) => (
                            <option key={i} value={item?.correctiveMethodName}>
                              {item?.correctiveMethodName}
                            </option>
                          ))
                        ) : (
                          <option key='no-data' value='' disabled>
                            No data found
                          </option>
                        )}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='correctiveActionDescription'>
                    <Form.Label column xs={6}>
                      Corrective Action Description :
                    </Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        as='textarea'
                        placeholder='Corrective Action Description'
                        className={
                          !isValidatedCorrectiveActionDescription
                            ? null
                            : isValidCorrectiveActionDescription
                            ? 'is-valid'
                            : 'is-invalid'
                        }
                        style={{ height: '100px', marginBottom: '8px' }}
                        value={correctiveActionDescription}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='causeType'>
                    <Form.Label column xs={6}>
                      Cause Type :
                    </Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        as='select'
                        autoComplete='off'
                        onChange={(event) => {
                          this.handleInputChange(event)
                        }}
                        className={
                          !isValidatedCauseType
                            ? null
                            : isValidCauseType
                            ? 'is-valid'
                            : 'is-invalid'
                        }
                        size='sm'
                        value={causeType}
                      >
                        <option key='' value=''>
                          Select Cause Type..
                        </option>
                        {Array.isArray(causeMethodList) && causeMethodList.length > 0 ? (
                          causeMethodList.map((item, i) => (
                            <option key={i} value={item?.causeMethodName}>
                              {item?.causeMethodName}
                            </option>
                          ))
                        ) : (
                          <option key='no-data' value='' disabled>
                            No data found
                          </option>
                        )}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='causeComments'>
                    <Form.Label column xs={6}>
                      Cause Comments :
                    </Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        as='textarea'
                        placeholder=' Cause Comments'
                        style={{ height: '100px', marginBottom: '8px' }}
                        className={
                          !isValidatedCauseComments
                            ? null
                            : isValidCauseComments
                            ? 'is-valid'
                            : 'is-invalid'
                        }
                        value={causeComments}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </Col>
                  </Form.Group>
                  {this.props.serviceType === 'Product Buyout' && (
                    <Card border='Light' className='mb-2'>
                      <Card.Body>
                        <Card.Text>
                          <Row xs={12} as={Row}>
                            <Col xs={5}>Product Type : </Col>
                            <Col xs={7}>{''}</Col>
                          </Row>
                          <Row xs={12} as={Row}>
                            <Col xs={5}>Product Name : </Col>
                            <Col xs={7}>{''}</Col>
                          </Row>
                          <Row xs={12} as={Row}>
                            <Col xs={5}>Sequence Number : </Col>
                            <Col xs={7}>{this.props.sequenceNumber}</Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )}
                  <Card border='Light' className='mb-2'>
                    <Card.Body>
                      <Card.Title>Payee Info</Card.Title>
                      <Card.Text>
                        <Row xs={12} as={Row}>
                          <Col xs={5}>Customer Name : </Col>
                          <Col xs={7}>{firstName + ' ' + lastName}</Col>
                        </Row>
                        <Row xs={12} as={Row}>
                          <Col xs={5}>Phone : </Col>
                          <Col xs={7}>{phone}</Col>
                        </Row>
                        <Row xs={12} as={Row}>
                          <Col xs={5}>Email : </Col>
                          <Col xs={7}>{email}</Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>

                  <Form.Group as={Row} controlId='reimbursementMethod'>
                    <Form.Label column xs={6}>
                      Reimbursement Method :
                    </Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        as='select'
                        autoComplete='off'
                        className={
                          !isValidatedReimbursementMethod
                            ? null
                            : isValidReimbursementMethod
                            ? 'is-valid'
                            : 'is-invalid'
                        }
                        onChange={(event) => {
                          this.handleInputChange(event)
                        }}
                        size='sm'
                        value={reimbursementMethod}
                      >
                        <option key='' value=''>
                          Select Reimbursement Type..
                        </option>
                        {['Paypal', 'Check'].map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Form.Label column='lg' className='text-center mb-3'>
                    Do you want to create a SubClaim for Customer Reimbursement?
                  </Form.Label>
                  <Row className='mb-3'>
                    <Form.Group as={Col} controlId='expectedLiability'>
                      <Form.Label> Expected Liability</Form.Label>
                      <InputGroup className='mb-3'>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                          className={
                            !isValidatedForSubClaim
                              ? null
                              : isValidateForSubClaim
                              ? 'is-valid'
                              : 'is-invalid'
                          }
                          aria-label='Amount (to the nearest dollar)'
                          type='number'
                          onChange={(event) => this.handleInputChange(event)}
                        />
                        <InputGroup.Text>.00</InputGroup.Text>
                      </InputGroup>
                      {isError && (
                        <Form.Label className='error-text'>Remaining liability Exceed</Form.Label>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} controlId='remainingLol'>
                      <Form.Label>Remaining LOL</Form.Label>
                      <FormControl
                        value={lolAvailableMoneyFormat ? lolAvailableMoneyFormat : ''}
                        disabled
                      />
                    </Form.Group>
                  </Row>
                </React.Fragment>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => closeSubClaimDialog()}>
                Close
              </Button>
              <Button variant='primary' onClick={() => this.handleSubmit()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {openCustomerInfoWindow && (
          <CustomerInfo
            openCustomerInfoDialog={this.openCustomerInfoDialog}
            closeCustomerInfoDialog={this.closeCustomerInfoDialog}
            country={country}
            reimbursementMethod={this.state.reimbursementMethod}
            closeSubClaimDialog={closeSubClaimDialog}
            handleCreateClaim={this.props.handleCreateClaim}
            openPaymentSuccessModal={openPaymentSuccessModal}
            handleOpenPaymentSuccessModal={handleOpenPaymentSuccessModal}
            handleClosePaymentSuccessModal={handleClosePaymentSuccessModal}
            correctiveAction={correctiveAction}
            correctiveActionDescription={correctiveActionDescription}
            causeType={causeType}
            causeComments={causeComments}
          />
        )}
      </React.Fragment>
    )
  }
}
export default Claim
