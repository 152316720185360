export const THOR = 'THOR'
export const ZLINE = 'ZLINE'
export const ZLINE_RECALL = 'ZLINE-RECALL'
export const CASE_FIELD_CLAIMS = [THOR, ZLINE, ZLINE_RECALL]
export const Constants = {
  overRide: 'Override',
}
export const dateField = {
  key2: 'installDate',
  key3: 'productPurchaseDate',
  key4: 'coverageBeginDate',
  key5: 'coverageEndDate',
  key6: 'failureDate',
  key7: 'appointmentDate',
  key9: 'consumerRequestDate',
}

export const moduleName = 'SA Screen'
export const application = 'SA Widget'
