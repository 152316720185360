import moment from 'moment'
import { dateField } from '../constants/index'

const validateDate = (dateString) => {
  return moment(dateString).isValid() ? dateString : ''
}

export function validatePayloadDates(payload) {
  if (Array.isArray(payload)) {
    return payload.map((item) => validatePayloadDates(item))
  } else if (typeof payload === 'object' && payload !== null) {
    Object.keys(payload).forEach((key) => {
      if (Object.values(dateField).includes(key)) {
        payload[key] = validateDate(payload[key])
      } else if (typeof payload[key] === 'object') {
        validatePayloadDates(payload[key])
      }
    })
  }
  return payload
}
