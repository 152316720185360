import { Component } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import Icon from '../../../img/DialogIcon.svg'

class PaymentSuccessModal extends Component {
  render() {
    const {
      openPaymentSuccessModal,
      closePaymentSuccessModal,
      firstName = '',
      lastName = '',
      email = '',
      phone = '',
      amount = '',
      reimbursementMethod = '',
      addressObj = {},
    } = this.props
    const handleSuccessMessage = () => {
      if (reimbursementMethod === 'Check') {
        return 'Your Check has been processed successfully'
      } else {
        return 'Your payment has been proceed Successfully'
      }
    }
    const isCheckMethod = reimbursementMethod === 'Check' ? true : false
    const {
      address = '',
      address1 = '',
      countryCode = '',
      city = '',
      stateCode = '',
      postalCode = '',
    } = addressObj || {}
    return (
      <Modal
        show={openPaymentSuccessModal}
        onHide={closePaymentSuccessModal}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          <div className='d-flex justify-content-center mt-3'>
            <img src={Icon} alt='icon' />
          </div>
          <Form.Label column='lg' className='text-center'>
            {handleSuccessMessage}
          </Form.Label>
          <Card border='Light' className='mt-4'>
            <Card.Body>
              <Card.Title className='text-center'>Payee Info</Card.Title>
              <Card.Text className='mb-2'>
                <Row xs={12} as={Row}>
                  <Col xs={5}>Customer Name : </Col>
                  <Col xs={7}>{firstName + ' ' + lastName}</Col>
                </Row>
                <Row xs={12} as={Row}>
                  <Col xs={5}>Email : </Col>
                  <Col xs={7}>{email}</Col>
                </Row>
                <Row xs={12} as={Row}>
                  <Col xs={5}>Phone : </Col>
                  <Col xs={7}>{phone}</Col>
                </Row>
                {isCheckMethod && (
                  <Row xs={12} as={Row}>
                    <Col xs={5}>Address : </Col>
                    <Col xs={7}>
                      {address +
                        ' ' +
                        address1 +
                        ' ' +
                        city +
                        ' ' +
                        stateCode +
                        ' ' +
                        countryCode +
                        ' ' +
                        postalCode}
                    </Col>
                  </Row>
                )}
                <Row xs={12} as={Row}>
                  <Col xs={5}>Amount Paid : </Col>
                  <Col xs={7}>{amount}</Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
          <div className='d-flex justify-content-center'>
            <Button variant='primary' onClick={closePaymentSuccessModal} className='pl-4 mt-3 '>
              Ok
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
export default PaymentSuccessModal
